import Box from "@mui/material/Box";
import DefaultModal, { ModalProps } from "@mui/material/Modal";
import { DialogTitle, Fade, IconButton, useTheme } from "@mui/material";
import Close from "@mui/icons-material/Close";

const Modal = (props: ModalProps) => {
  const theme = useTheme();
  return (
    <DefaultModal
      {...props}
      sx={{ display: "flex", px: 2, py: 1.7, ...props?.sx }}
    >
      <Fade style={{ width: "100%" }} in={props?.open}>
        <Box
          sx={{
            backgroundColor: theme?.colors?.alpha?.white[100],
            m: "auto",
            position: "relative",
          }}
        >
          <IconButton
            sx={{
              position: "absolute",
              right: -10,
              top: -10,
              backgroundColor: theme?.colors?.error?.main,
              borderRadius: 5,
              border: `3px solid ${theme?.colors?.alpha?.white[100]}`,
              padding: 0.2,
              zIndex: 9,
              ":hover": {
                backgroundColor: theme?.colors?.error?.dark,
              },
            }}
            onClick={() => {
              props?.onClose && props?.onClose({}, "escapeKeyDown");
            }}
          >
            <Close
              fontSize="small"
              sx={{ color: theme?.colors?.alpha?.white[100] }}
            />
          </IconButton>
          {props?.title ? <DialogTitle>{props?.title}</DialogTitle> : null}
          <Box
            sx={{
              maxHeight: document?.body?.clientHeight - 25,
              overflow: "auto",
              overflowX: "hidden",
            }}
          >
            {props?.children}
          </Box>
        </Box>
      </Fade>
    </DefaultModal>
  );
};

export default Modal;
